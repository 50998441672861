import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import axios from 'axios'

class UserService {
  getNotificationBeforeLogin() {
    return axios.get(Const.api_url + 'before-login-notifications')
  }

  getUserInfo() {
    return axiosApiInstance.get(Const.api_url + 'members/me/info')
  }

  getUserProfile() {
    return axiosApiInstance.get(Const.api_url + 'members/me/profile')
  }

  getUserProfileById(user_id: any) {
    return axiosApiInstance.get(Const.api_url + 'members/me/get-profile-by-user-id/' + user_id)
  }

  getUserCompanyInfo() {
    return axiosApiInstance.get(Const.api_url + 'members/me/company-info')
  }

  getUserPersonalInfo() {
    return axiosApiInstance.get(Const.api_url + 'members/me/personal-info')
  }

  getUserPersonalInfoById(user_id: any) {
    return axiosApiInstance.get(Const.api_url + 'members/me/personal-info/' + user_id)
  }

  changeEmail(accountChangeEmail: any) {
    return axiosApiInstance.post(Const.api_url + 'members/me/change-email', accountChangeEmail)
  }

  changePassword(accountChangePassword: any) {
    return axiosApiInstance.post(Const.api_url + 'members/me/change-password', accountChangePassword)
  }

  updateUserProfile(new_profile: any) {
    return axiosApiInstance.post(Const.api_url + 'members/me/profile', new_profile)
  }

  verifyEmailChange(user_id: any, data: any) {
    return axiosApiInstance.get(Const.api_url + 'verify-email/' + user_id, data)
  }

  updateUserCompanyInfo(new_company_info: any) {
    return axiosApiInstance.post(Const.api_url + 'members/me/company-info', new_company_info)
  }

  updateUserPersonalInfo(new_personal_info: any) {
    return axiosApiInstance.post(Const.api_url + 'members/me/personal-info', new_personal_info)
  }

  updateAccountStatus(data: any) {
    return axiosApiInstance.post(Const.api_url + 'members/me/update-account-status', data)
  }

  getZipCode(zipCode: string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/zipcode/${zipCode}`)
  }
}

export default new UserService